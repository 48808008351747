import React from "react";
import theme from "theme";
import { Theme, Text, Box, Image, Section, Strong, Button } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"service"} />
		<Helmet>
			<title>
				Наші послуги в Котеджних Мріях
			</title>
			<meta name={"description"} content={"Мистецтво ідеального простору – відкрийте наш світ дизайну та планування"} />
			<meta property={"og:title"} content={"Наші послуги в Котеджних Мріях"} />
			<meta property={"og:description"} content={"Мистецтво ідеального простору – відкрийте наш світ дизайну та планування"} />
			<meta property={"og:image"} content={"https://kyivkaleidoscope.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://kyivkaleidoscope.com/img/favicon-main.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://kyivkaleidoscope.com/img/favicon-main.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://kyivkaleidoscope.com/img/favicon-main.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://kyivkaleidoscope.com/img/favicon-main.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://kyivkaleidoscope.com/img/favicon-main.png"} />
			<meta name={"msapplication-TileImage"} content={"https://kyivkaleidoscope.com/img/favicon-main.png"} />
		</Helmet>
		<Components.Header />
		<Section padding="80px 0 80px 0" sm-padding="60px 0 60px 0">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				width="50%"
				flex-direction="column"
				justify-content="center"
				align-items="flex-start"
				lg-align-items="center"
				lg-margin="0px 0px 20px 0px"
				sm-padding="0px 0px 0px 0px"
				padding="16px 16px 16px 0px"
				lg-width="100%"
				md-margin="0px 0px 20px 0px"
				sm-margin="0px 0px 30px 0px"
			>
				<Text
					margin="0px 0px 0px 0px"
					color="--dark"
					font="--headline1"
					lg-text-align="center"
					sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					padding="0px 16px 0px 0px"
				>
					Основні послуги
				</Text>
			</Box>
			<Box
				display="flex"
				width="50%"
				flex-direction="column"
				justify-content="flex-start"
				align-items="flex-start"
				lg-align-items="center"
				lg-margin="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
				padding="28px 0px 16px 16px"
				lg-width="100%"
				md-margin="0px 0px 20px 0px"
				sm-margin="0px 0px 0px 0px"
			>
				<Text
					margin="0px 0px 0px 0px"
					color="--darkL1"
					font="--lead"
					lg-text-align="center"
					display="flex"
					align-items="flex-start"
				>
					У Котеджних Мріях ми пропонуємо комплекс послуг, призначених для трансформації вашого житлового простору в ідеальний дім. Від унікального дизайну інтер'єру до ексклюзивного ландшафтного дизайну, ми ваш ключ до створення омріяного простору.
				</Text>
			</Box>
			<Box
				display="grid"
				width="100%"
				margin="80px 0px 0px 0px"
				lg-margin="40px 0px 0px 0px"
				grid-template-columns="repeat(3, 1fr)"
				grid-gap="32px"
				sm-grid-template-columns="1fr"
			>
				<Image
					src="https://kyivkaleidoscope.com/img/4.jpg"
					object-fit="cover"
					width="100%"
					height="100%"
					grid-column="1 / span 1"
					border-radius="24px"
				/>
				<Image
					src="https://kyivkaleidoscope.com/img/5.jpg"
					object-fit="cover"
					width="100%"
					height="100%"
					grid-column="2 / span 2"
					border-radius="24px"
					sm-grid-column="1 / span 1"
				/>
			</Box>
		</Section>
		<Section padding="48px 0 48px 0" sm-padding="60px 0 60px 0" quarkly-title="About-14">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" sm-min-width="280px" />
			<Box
				display="flex"
				width="40%"
				justify-content="flex-start"
				lg-width="100%"
				align-items="flex-start"
				lg-margin="0px 0px 0px 0px"
				margin="0px 0px 0px 0px"
				lg-padding="0px 0px 0px 0px"
				lg-justify-content="center"
			>
				<Image
					src="https://kyivkaleidoscope.com/img/6.jpg"
					object-fit="cover"
					transform="translateY(0px)"
					transition="transform 0.2s ease-in-out 0s"
					hover-transform="translateY(-10px)"
					sm-min-height="100vw"
					height="100%"
				/>
			</Box>
			<Box
				display="flex"
				width="60%"
				flex-direction="column"
				align-items="flex-start"
				lg-width="100%"
				lg-align-items="flex-start"
				lg-margin="0px 0px 0px 0px"
				padding="48px 64px 48px 64px"
				justify-content="center"
				background="white"
				md-padding="36px 40px 48px 40px"
				sm-padding="36px 24px 48px 24px"
			>
				<Text
					margin="0px 0px 16px 0px"
					color="--dark"
					font="--headline3"
					lg-text-align="center"
					sm-text-align="left"
				>
					Дизайн і планування будинків та котеджів
				</Text>
				<Text
					margin="0px 0px 40px 0px"
					color="--greyD2"
					font="--base"
					lg-text-align="left"
					sm-text-align="left"
				>
					<Strong>
						• Архітектурне проєктування:
					</Strong>
					{" "}Розробка індивідуальних проєктів будинків та котеджів, що відповідають вашим унікальним потребам та стилю.
					<br />
					<Strong>
						• Дизайн інтер'єру:
					</Strong>
					{" "}Створення гармонійного та функціонального інтер'єру, який втілює ваші мрії та побажання в кожному куточку.
				</Text>
				<Text
					margin="0px 0px 16px 0px"
					color="--dark"
					font="--headline3"
					lg-text-align="center"
					sm-text-align="left"
				>
					Ландшафтний дизайн та планування
				</Text>
				<Text
					margin="0px 0px 40px 0px"
					color="--greyD2"
					font="--base"
					lg-text-align="left"
					lg-margin="0px 0px 36px 0px"
					sm-text-align="left"
				>
					<Strong>
						• Створення ландшафту:
					</Strong>
					{" "}Розробка унікальних ландшафтних проєктів, що ідеально доповнюють архітектуру вашого дому та відображають природну красу навколишнього середовища.
					<br />
					<Strong>
						• Садові композиції:
					</Strong>
					{" "}Планування та реалізація садових композицій, що створюють ідеальні умови для вашого відпочинку та насолоди природою.
				</Text>
			</Box>
		</Section>
		<Section padding="48px 0 48px 0" sm-padding="60px 0 60px 0" quarkly-title="About-14">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" sm-min-width="280px" />
			<Box
				display="flex"
				width="60%"
				flex-direction="column"
				align-items="flex-start"
				lg-width="100%"
				lg-align-items="flex-start"
				lg-margin="0px 0px 0px 0px"
				padding="48px 64px 48px 64px"
				justify-content="center"
				background="white"
				md-padding="36px 40px 48px 40px"
				sm-padding="36px 24px 48px 24px"
			>
				<Text
					margin="0px 0px 16px 0px"
					color="--dark"
					font="--headline3"
					lg-text-align="center"
					sm-text-align="left"
				>
					Розширений список послуг
				</Text>
				<Text
					margin="0px 0px 40px 0px"
					color="--greyD2"
					font="--base"
					lg-text-align="left"
					sm-text-align="left"
				>
					<Strong>
						• Ескізне проєктування:
					</Strong>
					{" "}Розвиток первісної концепції вашого майбутнього дому чи котеджу, включаючи візуалізацію зовнішнього вигляду та внутрішнього простору.
					<br />
					<Strong>
						• Детальне планування:
					</Strong>
					{" "}Підготовка детальних планів будівництва, електрики, сантехніки та вентиляції, забезпечуючи повну готовність проєкту до реалізації.
					<br />
					<Strong>
						• Консультації з озеленення:
					</Strong>
					{" "}Пропонуємо професійні консультації з вибору рослин та їх розміщення на вашій території для створення гармонійного та збалансованого ландшафту.
					<br />
					<Strong>
						• Дизайн екстер'єру:
					</Strong>
					{" "}Розробка унікального зовнішнього дизайну, що включає фасади будинків, вхідні групи, тераси та басейни, для створення цілісного образу вашої домівки.
					<br />
					<Strong>
						• Адаптація готових проєктів:
					</Strong>
					{" "}Модифікація та адаптація існуючих архітектурних проєктів згідно з вашими особистими потребами та умовами ділянки.
					<br />
					<Strong>
						• Світловий дизайн:
					</Strong>
					{" "}Проєктування систем освітлення для інтер'єру та екстер'єру, створення оптимального освітлення, що підкреслює архітектурну красу та створює відповідний настрій.
					<br />
					<Strong>
						• Авторський нагляд:
					</Strong>
					{" "}Послуги авторського нагляду за ходом будівництва та озеленення, забезпечуючи відповідність реалізації проєкту первісному задуму.
					<br />
					<Strong>
						• 3D-візуалізація:
					</Strong>
					{" "}Створення деталізованих 3D-візуалізацій інтер'єрів та екстер'єрів для повного уявлення про майбутній проєкт до початку реалізації.
					<br />
					<Strong>
						• Екологічно відповідальні рішення:
					</Strong>
					{" "}Розробка проєктів з використанням екологічно чистих матеріалів та технологій, забезпечуючи сталість та мінімальний вплив на навколишнє середовище.
				</Text>
			</Box>
			<Box
				display="flex"
				width="40%"
				justify-content="flex-start"
				lg-width="100%"
				align-items="flex-start"
				lg-margin="0px 0px 0px 0px"
				margin="0px 0px 0px 0px"
				lg-padding="0px 0px 0px 0px"
				lg-justify-content="center"
			>
				<Image
					src="https://kyivkaleidoscope.com/img/7.jpg"
					object-fit="cover"
					transform="translateY(0px)"
					transition="transform 0.2s ease-in-out 0s"
					hover-transform="translateY(-10px)"
					sm-min-height="100vw"
					height="100%"
				/>
			</Box>
		</Section>
		<Section padding="90px 0 100px 0" quarkly-title="List-5">
			<Box
				min-width="100px"
				min-height="100px"
				display="grid"
				grid-template-columns="repeat(2, 1fr)"
				grid-gap="74px"
				sm-grid-template-columns="1fr"
				sm-grid-gap="36px 0"
				md-grid-gap="50px 0"
				margin="0px 50px 0px 50px"
				lg-margin="0px 0 0px 0"
				md-grid-template-columns="1fr"
			>
				<Image src="https://kyivkaleidoscope.com/img/8.jpg" display="block" width="100%" md-order="-1" />
				<Box
					min-width="10px"
					min-height="10px"
					display="flex"
					flex-direction="column"
					padding="0px 50px 0px 0px"
					align-items="flex-start"
					md-padding="0px 0 0px 0px"
				>
					<Text margin="0px 0px 25px 0px" font="normal 500 28px/1.2 --fontFamily-sans" md-margin="0px 0px 20px 0px">
						Чому слід обирати Котеджні Мрії
					</Text>
					<Text margin="0px 0px 35px 0px" font="normal 300 18px/1.5 --fontFamily-sansHelvetica" color="#474a4d" md-margin="0px 0px 25px 0px">
						Персоналізовані рішення: Ми пишаємось можливістю надавати персоналізовані рішення, які точно відповідають вашим індивідуальним вимогам та мріям.
						<br />
						<br />
						Цілісний підхід: Наша команда фахівців працює з вами на кожному етапі процесу, від першої консультації до фінальної реалізації проєкту.
					</Text>
					<Button
						href="/contacts"
						type="link"
						text-decoration-line="initial"
						background="--color-primary"
						padding="11px 28px 11px 28px"
						border-radius="25px"
					>
						Звʼязатися
					</Button>
				</Box>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});